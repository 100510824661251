import * as React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Default from '../../layouts/default';
import { zapFundBlockMapper } from 'core/pageHelpers/zapFund';

const ZapFundPage: React.FC<{ data: any }> = ({ data }) => {
  const zapFundData: GatsbyTypes.ContentfulPage = data.zapFund;
  const zapFundPage = zapFundData.edges[0].node;
  const seo = zapFundPage.seo;

  const blocks = Object.values(zapFundBlockMapper(zapFundPage.pageBlocks));

  return (
    <Default>
      <SEO data={seo} />
      {blocks.map((block) => block)}
    </Default>
  );
};

export default ZapFundPage;

export const pageQuery = graphql`
  {
    zapFund: allContentfulPage(filter: { page: { eq: "ZAP Fund" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...defaultFields
        }
      }
    }
  }
`;
